<template>
  <v-row>
    <v-col cols="11" sm="3" class="wb-form">
      <form>
        <v-text-field
            v-model="username"
            label="Identifiant"
            outlined
        ></v-text-field>
        <v-text-field
            v-model="password"
            label="Mot de passe"
            type="password"
            outlined
        ></v-text-field>
        <v-btn color="primary" v-on:click="login">Connexion</v-btn>
      </form>
    </v-col>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Identifiants incorrects.
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";
import axios from 'axios';
import { loginUser } from '../utils/auth'

export default {
  name: 'WBLogin',
  data: () => {
    return {
      error: false,
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapMutations(["setUser", "setToken"]),
    authIsValid () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/authenticate',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(function () {
        return true
      }).catch(function () {
        return false
      });
    },
    async login() {
      try {
        if(this.username === 'axel' || this.username === 'hugobynature') {
          await loginUser(this.username, this.password)
          this.$router.push('/')
        } else {
          this.error = true
        }
      }
      catch (err) {
        this.error = true
      }
    },
  },
};
</script>
<style scoped>
.wb-form{
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
