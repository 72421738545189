<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="text-left ml-3">AJOUTER UN PARI</h2>
        <form class="wb-form">
          <v-row>
            <v-col>
              <v-text-field
                  label="Libellé du pari"
                  counter="255"
                  outlined
                  v-model="pari.libelle"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="Visuel du pari"
                  counter="255"
                  outlined
                  v-model="pari.image"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  :items="categories"
                  v-model="pari.categorie"
                  item-text="libelle"
                  item-value="id"
                  label="Catégorie du paris"
                  outlined
              ></v-select>

              <v-switch
                  v-model="pari.sendNotif"
                  label="Envoyer une notification"
              ></v-switch>
            </v-col>
            <v-col>
              <v-datetime-picker label="Date de fin" dateFormat="dd-MM-yyyy" v-model="pari.dateFin" :textFieldProps="{ outlined: true, locale: 'fr' }"> </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row align="center"
                 justify="center">
              <v-col cols="10" class="text-left">
                <h3>LISTE DES PARTICIPANTS</h3>
              </v-col>
              <v-col class="text-right" cols="2">
                <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="indigo"
                    @click="showAjoutParticipant = true"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            <v-col cols="12" >
              <v-list two-line>
                <v-list-item-group
                    class="pink--text"
                    multiple
                >

                  <template v-for="(item, index) in pari.participants">
                    <v-list-item :key="item.nom">
                      <template>
                        <v-list-item-content class="text-left">
                          <v-list-item-title>{{ item.nom }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text>
                            <v-chip
                                class="ma-2"
                                color="orange"
                                text-color="white"
                            >
                              <v-icon left>
                                mdi-star
                              </v-icon>
                              {{item.cote}}
                            </v-chip>
                            <v-btn
                                class="mx-2"
                                fab
                                small
                                dark
                                color="indigo"
                                @click="removeParticipant(item.nom)"
                            >
                              <v-icon dark>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                        v-if="index < pari.participants.length - 1"
                        :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.pari.libelle || !this.pari.image || !this.pari.dateFin || !this.pari.categorie || !this.pari.participants.length > 0"
          >
            Enregistrer le pari
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="6">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.pari.libelle || this.pari.image"
        >
          <v-img
              :src="pari.image"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.pari.libelle }}
          </v-card-title>
          <v-card-subtitle>
            <v-list two-line>
              <v-list-item-group
                  class="pink--text"
                  multiple
              >

                <template v-for="(item, index) in pari.participants">
                  <v-list-item :key="item.nom">
                    <template>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ item.nom }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-chip
                              class="ma-2"
                              color="orange"
                              text-color="white"
                          >
                            <v-icon left>
                              mdi-star
                            </v-icon>
                            {{item.cote}}
                          </v-chip>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                      v-if="index < pari.participants.length - 1"
                      :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      Le pari a été ajouté.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Le pari n'a pas été ajouté.
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showAjoutParticipant"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Ajout d'un participant</v-toolbar>
          <v-card-text>
            <v-row class="mt-5">
              <v-col cols="8">
                <v-text-field
                    label="Nom du participant"
                    outlined
                    v-model="participant.nom"
                    counter="255"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    label="Cote"
                    outlined
                    v-model="participant.cote"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                class="ma-3 white--text"
                @click="ajouterParticipant()"
            >Ajouter</v-btn>
            <v-btn
                text
                @click="dialog.value = false"
            >Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBWelcome',
  data: function () {
    return {
      showAjoutParticipant: false,
      pari: {
        libelle : null,
        dateFin: null,
        categorie: null,
        sendNotif: true,
        image: null,
        participants: []
      },
      participant: {
        nom: null,
        cote: null
      },
      categories: [],
      success: false,
      error: false
    };
  },
  methods: {
    getCategories () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/categories/actives',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.categories = response.data);
    },
    addHours(numOfHours, date) {
      date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
      return date;
    },
    save() {
      this.pari.dateFin = this.addHours(2, this.pari.dateFin)
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.pari
      }).then(() => this.success = true).catch(() => this.error = true)
          .finally(() => this.pari = null)
      setTimeout( () => this.$router.push({ path: '/paris'}), 1000);
    },
    ajouterParticipant() {
      this.pari.participants.push(this.participant)
      this.showAjoutParticipant = false
      this.participant = {}
    },
    removeParticipant(nom) {
      this.removeByAttr(this.pari.participants, 'nom', nom);
    },
    removeByAttr (arr, attr, value){
      var i = arr.length;
      while(i--){
        if( arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 2 && arr[i][attr] === value ) ){

          arr.splice(i,1);

        }
      }
      return arr;
    }
  },
  mounted () {
    this.getCategories()
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
