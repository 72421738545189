<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >

    <v-row align="center" justify="center" style="margin-bottom:10px;">
      <v-col :class="isMobile() ? 'text-center' : 'text-left'">
        <h2>LES ANNONCES</h2>
      </v-col>
      <v-col :class="isMobile() ? 'text-center' : 'text-end'">
        <v-btn
            color="primary"
            class="ma-3 white--text"
            to="/annonces/ajouter"
        >
          <v-icon
              left
              dark
          >
            mdi-plus
          </v-icon>
          AJOUTER UNE ANNONCE
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :headers="isMobile() ? [] : headers"
        :items="annonces"
        :items-per-page="5"
        :class="isMobile() ? '' : 'elevation-1'"
        :hide-default-footer="isMobile() ? true : false"
        no-data-text="Aucune annonce"
        no-results-text="Aucune annonce"
        @click:row="showDetail"
    >
      <template v-if="isMobile()" v-slot:item="props" >
        <v-card :to="'annonces/' + props.item.id + '/modification'" style="margin-bottom:20px;">
          <v-img :src="props.item.image" width="95vw" v-if="item.image != null" />
          <v-card-title>{{ props.item.titre }}</v-card-title>
          <dic style="text-align: left">
            {{ props.item.dateFin | formatDate}}
          </dic>
        </v-card>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" v-if="item.image != null" />
      </template>
      <template v-slot:[`item.dateFin`]="{ item }">
        {{ item.dateFin | formatDate }}
      </template>
      <template v-slot:[`item.button`]="{ item }">
        <i class="mdi mdi-check" style="color:green;font-size:30px" v-if="new Date(item.dateFin) > new Date().getTime()"></i>
        <i class="mdi mdi-close" style="color:red;font-size:30px"  v-else></i>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBAnnonces',
  data: function () {
    return {
      annonces: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Visuel', value: 'image' },
        { text: 'Titre', value: 'title' },
        { text: 'Description', value: 'content' },
        { text: 'Affichée', value: 'button' },
        { text: 'Fin d\'affichage', value: 'dateFin' }
      ],
    }
  },
  methods: {
    showDetail (value) {
      this.$router.push('/annonces/' + value.id + '/modification')
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/informations-all',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.annonces = response.data);
  }
};
</script>
<style lang="scss">
.cagnotte {
  font-weight: 800;
  font-size: 18px;
}
</style>
