<template>
  <v-container
      id="dashboard-view"
      fluid
      tag="section"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="text-left ml-3">MODIFIER UNE ANNONCE</h2>
        <form class="wb-form">
          <v-row>
            <v-col>
              <v-text-field
                  label="Titre de l'annonce en Français"
                  outlined
                  v-model="annonce.title"
              ></v-text-field>
              <v-text-field
                  label="Titre de l'annonce en Anglais"
                  outlined
                  v-model="annonce.titleEn"
              ></v-text-field>

              <v-text-field
                  label="Visuel de l'annonce"
                  outlined
                  v-model="annonce.image"
              ></v-text-field>

              <v-text-field
                  label="Bouton d'action en Français"
                  outlined
                  v-model="annonce.button"
              ></v-text-field>

              <v-text-field
                  label="Bouton d'action en Anglais"
                  outlined
                  v-model="annonce.buttonEn"
              ></v-text-field>

              <v-text-field
                  label="Lien du bouton"
                  outlined
                  v-model="annonce.link"
              ></v-text-field>

              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="annonce.dateFin"
                      outlined
                      label="Date de fin d'affichage"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="annonce.dateFin"
                    @input="menu2 = false"
                    dateFormat="dd-MM-yyyy"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-textarea
                  label="Contenu de l'annonce en Français"
                  outlined
                  v-model="annonce.content"
              ></v-textarea>
              <v-textarea
                  label="Contenu de l'annonce en Anglais"
                  outlined
                  v-model="annonce.contentEn"
              ></v-textarea>

              <v-switch
                  v-model="annonce.sso"
                  label=" Lien SSO"
              ></v-switch>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.annonce.title || !this.annonce.content || !this.annonce.contentEn || !this.annonce.titleEn || !this.annonce.dateFin"
          >
            Publier l'annonce
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="6">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            color="primary"
            style="color:white;"
            width="100%"
            v-if="this.annonce.title || this.annonce.image"
        >
          <v-img
              :src="annonce.image"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.annonce.title }}
          </v-card-title>
          <v-card-content style="text-align: left;display: block;padding:20px;">
            {{ this.annonce.content }}
          </v-card-content>
          <v-row>
            <v-col cols="6" style="margin:10px">
              <v-btn block color="white" v-if="this.annonce.button">
                {{ this.annonce.button }}
              </v-btn>
            </v-col>
            <v-col cols="1">
            </v-col>
            <v-col cols="4" style="margin:10px">
              <v-btn block color="black" style="color:white;">
                Fermer
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      L'annonce a été publiée'.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. L'annonce n'a pas été publiée.
    </v-snackbar>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBWelcome',
  data: function () {
    return {
      menu2: false,
      annonce: {
        title : null,
        titleEn: null,
        content: null,
        contentEn: null,
        dateFin: null,
        image: null,
        button: null,
        buttonEn: null,
        link: null,
        sso: false
      },
      success: false,
      error: false
    };
  },
  methods: {
    save() {

      if(this.annonce.sso) {
        this.annonce.sso = 1;
      } else {
        this.annonce.sso = 0;
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/informations',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.annonce
      }).then(() => this.success = true).catch(() => this.error = true)
          .finally(() => this.annonce = null)
      setTimeout( () => this.$router.push({ path: '/annonces'}), 1000);
    }
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
