<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="text-left ml-3">MODIFIER UN TWEET</h2>
        <form class="wb-form">
          <v-row>
            <v-col>
              <v-textarea
                  label="Texte"
                  counter="255"
                  outlined
                  v-model="tweet.text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Visuel du pari"
                  counter="255"
                  outlined
                  v-model="tweet.image"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
          >
            Enregistrer le tweet
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="6">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.tweet.text || this.tweet.image"
        >
          <v-img
              :src="tweet.image"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.tweet.text }}
          </v-card-title>
          <v-card-subtitle>
            <a :href="tweet.url" target="_blank">Lien de l'article</a>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      Le pari a été ajouté.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Le pari n'a pas été ajouté.
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showAjoutParticipant"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Ajout d'un participant</v-toolbar>
          <v-card-text>
            <v-row class="mt-5">
              <v-col cols="8">
                <v-text-field
                    label="Nom du participant"
                    outlined
                    v-model="participant.nom"
                    counter="255"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    label="Cote"
                    outlined
                    v-model="participant.cote"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                class="ma-3 white--text"
                @click="ajouterParticipant()"
            >Ajouter</v-btn>
            <v-btn
                text
                @click="dialog.value = false"
            >Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'TweetsAjout',
  data: function () {
    return {
      tweet: {
        text : null,
        image: null
      },
      success: false,
      error: false
    };
  },
  methods: {
    save() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/tweets/' + this.$route.params.id,
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.tweet
      }).then(() => this.success = true).catch(() => this.error = true).finally(() =>{
        this.$router.push('/tweets')
      })
    }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/tweets/' + this.$route.params.id,
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => {
      this.tweet = response.data
    });
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
