import axios from 'axios'

const REST_ENDPOINT = 'http://localhost:9090'
const AUTH_TOKEN_KEY = 'authToken'

export function loginUser(username, password) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: process.env.VUE_APP_API_ROOT_URL + '/login',
                method: 'POST',
                responseType: 'text',
                data: {
                    username: username,
                    password: password
                }
            })


            setAuthToken(res.data)
            resolve()
        }
        catch (err) {
            console.error('Caught an error during login:', err)
            reject(err)
        }
    })
}

export function logoutUser() {
    clearAuthToken()
}

export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function logOut() {
    clearAuthToken()
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    if(!!authToken) {
        axios({
            method: 'get',
            url: 'http://localhost:9090/authenticate',
            headers: {
                'Authorization': 'Bearer ' + authToken
            }
        }).catch(function () {
            return false
        });
        return true
    }
    return false
}
