<template>
  <v-container
      id="dashboard-view"
      fluid
      tag="section"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="text-left ml-3">PARAMÈTRES DES PUBLICITÉS</h2>
        <form class="wb-form mt-5">
          <v-row>
            <v-col cols="12">
              <v-switch
                  v-model="parametres.lancement"
                  label="Publicité au lancement de l'application"
              ></v-switch>
              <v-switch
                  v-model="parametres.classementI"
                  label="Publicité lors de l'affichage du classement internationnal"
              ></v-switch>
              <v-switch
                  v-model="parametres.classementN"
                  label="Publicité lors de l'affichage du classement nationnal"
              ></v-switch>
              <v-switch
                  v-model="parametres.premium"
                  label="Afficher tout ce qui est relatif aux comptes PREMIUM"
              ></v-switch>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()">
            Enregistrer
          </v-btn>
        </form>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      Les paramètres ont bien été enregistrés.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Les paramètres n'ont pas été enregistrés.
    </v-snackbar>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBPUB',
  data: function () {
    return {
      parametres: {
        lancement: null,
        classementN: null,
        classementI: null,
        premium: null
      },
      success: false,
      error: false
    }
  },
  methods: {
  save() {
    axios({
      method: 'post',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/pubs',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      },
      data: this.parametres
    }).then(() => {
      this.success = true
    }).catch(() => this.error = true)
  }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/pubs',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.parametres = response.data);
  }
};
</script>
<style lang="scss">
.cagnotte {
  font-weight: 800;
  font-size: 18px;
}
</style>
