<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="9">
        <h2 class="text-left ml-3">AJOUTER UN NIVEAU</h2>
        <form class="wb-form">
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Libellé du niveau"
                  outlined
                  v-model="niveau.libelle"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Logo du niveau"
                  outlined
                  v-model="niveau.icon"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Seuil du niveau"
                  outlined
                  v-model="niveau.seuil"
              ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
              <v-text-field
                  label="Plafond du niveau"
                  outlined
                  v-model="niveau.plafond"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Valeur de la pub"
                  outlined
                  v-model="niveau.pub"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Valeur du Jackpot"
                  outlined
                  v-model="niveau.jackpot"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Couleur principale"
                  outlined
                  v-model="niveau.primaryColor"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Couleur secondaire"
                  outlined
                  v-model="niveau.secondaryColor"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.niveau.libelle || !this.niveau.icon"
          >
            Enregistrer le niveau
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="3">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.niveau.libelle || this.niveau.icon"
        >
          <v-img
              :src="niveau.icon"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.niveau.libelle }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      Le niveau a été ajouté.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Le niveau n'a pas été ajouté.
    </v-snackbar>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBAjoutCategories',
  data: function () {
    return {
      niveau: {
        libelle : null,
        icon: null,
        seuil: null,
        plafond: null,
        pub: null,
        jackpot: null,
        primaryColor: null,
        secondaryColor: null
      },
      success: false,
      error: false
    };
  },
  methods: {
    save() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/niveaux',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.niveau
      }).then(() => this.success = true).catch(() => this.error = true)
          .finally(() => this.niveau = null)
      setTimeout( () => this.$router.push({ path: '/niveaux'}), 1000);
    }
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
