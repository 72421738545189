<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="9">
        <h2 class="text-left ml-3">MODIFIER UNE CATÉGORIE</h2>
        <form class="wb-form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                  label="Libellé de la catégorie"
                  outlined
                  v-model="categorie.libelle"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                  label="Logo de la catégorie"
                  outlined
                  v-model="categorie.logo"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-switch
                  v-model="categorie.actif"
                  label="Actif"
                  color="primary"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.categorie.libelle || !this.categorie.logo"
          >
            Enregistrer la catégorie
          </v-btn>
          <v-btn
              color="error"
              class="ma-3 white--text"
              @click="showConfirmation = true"
          >
            Supprimer la catégorie
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="3">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.categorie.libelle || this.categorie.logo"
        >
          <v-img
              :src="categorie.logo"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.categorie.libelle }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      La catégorie a été modifiée.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. La catégorie n'a pas été modifiée.
    </v-snackbar>
    <v-snackbar
        v-model="errorSupp"
        color="error"
    >
      Erreur. La catégorie ne peut pas être supprimée car elle est lié à plusieurs paris.
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showConfirmation"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Supprimer une catégorie</v-toolbar>
          <v-card-title>
            Êtes-vous sur de vouloir supprimer la catégorie " {{ categorie.libelle }} " ?
          </v-card-title>
          <v-card-actions class="justify-end">
            <v-btn
                color="error"
                class="ma-3 white--text"
                @click="deleteCategorie()"
            >Supprimer</v-btn>
            <v-btn
                text
                @click="dialog.value = false"
            >Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBAjoutCategories',
  data: function () {
    return {
      showConfirmation: false,
      categorie: {
        libelle : null,
        logo: null,
        actif: true
      },
      errorSupp: false,
      success: false,
      error: false
    };
  },
  methods: {
    getCategorie () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/categories/' + this.$route.params.id,
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.categorie = response.data);
    },
    deleteCategorie () {
      axios({
        method: 'delete',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/categories/' + this.$route.params.id,
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then(() => {
        setTimeout( () => this.$router.push({ path: '/categories'}), 1000);
      }).catch(() => {
        this.errorSupp = true;
      });
    },
    save() {
      axios({
        method: 'put',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/categories',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.categorie
      }).then(() => this.success = true).catch(() => this.error = true)
          .finally(() => this.categorie = null)
      setTimeout( () => this.$router.push({ path: '/categories'}), 1000);
    }
  },
  mounted () {
    this.getCategorie()
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
