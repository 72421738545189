<template>
  <v-app id="app">
    <v-app-bar color="primary" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>DASHBOARD</v-app-bar-title>
      <v-spacer />
      <v-btn @click="logOut" to="/login" v-if="isLoggedIn()">Déconnexion</v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> WRESTLING BET </v-list-item-title>
          <v-list-item-subtitle> L'appli de pronos sur le catch</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav >
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { isLoggedIn, logOut } from './utils/auth'

export default {
  data: function () {
    return {
      drawer: false,
      items: [
        { title: 'DASHBOARD', icon: 'mdi-view-dashboard', to: '/' },
        { title: 'LES PARIS', icon: 'mdi-account-box', to: '/paris' },
        { title: 'LES MEMBRES', icon: 'mdi-cog' , to: '/membres' },
        { title: 'LES ANNONCES', icon: 'mdi-cog' , to: '/annonces' },
        { title: 'LES NOTIFICATIONS', icon: 'mdi-cog' , to: '/notifications' },
        { title: 'LES CATÉGORIES', icon: 'mdi-cog' , to: '/categories' },
        { title: 'LES NIVEAUX', icon: 'mdi-cog' , to: '/niveaux' },
        { title: 'LES ACHATS', icon: 'mdi-cog' , to: '/achats' },
        { title: 'LES PUBLICITÉS', icon: 'mdi-cog' , to: '/publicites' },
        { title: 'TWEETS', icon: 'mdi-cog' , to: '/tweets' }
      ]
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn()
    },
    logOut() {
      return logOut()
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
