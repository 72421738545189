import Vue from "vue";
import VueRouter from "vue-router";
import WBLogin from "../views/WBLogin.vue";
import WBDashboard from "../views/WBDashboard.vue";
import WBParis from "../views/WBParis.vue";
import WBDraw from "../views/WBDraw.vue";
import WBParisList from "../views/WBParisList.vue";
import WBParisAjout from "../views/WBParisAjout.vue";
import WBNiveauxAjout from "../views/WBNiveauxAjout.vue";
import WBCategoriesAjout from "../views/WBCategoriesAjout.vue";
import WBCategoriesModif from "../views/WBCategoriesModif.vue";
import WBNiveauxModif from "../views/WBNiveauxModif.vue";
import WBParisModif from "../views/WBParisModif.vue";
import WBMembres from "../views/WBMembres.vue";
import WBNotifications from "../views/WBNotifications.vue";
import WBPUB from "../views/WBPUB.vue";
import WBAnnonces from "../views/WBAnnonces.vue";
import WBAnnoncesModif from "../views/WBAnnoncesModif.vue";
import WBAnnoncesAjout from "../views/WBAnnoncesAjout.vue";
import WBCategories from "../views/WBCategories.vue";
import WBAchats from "../views/WBAchats.vue";
import WBTweets from "../views/Tweets.vue";
import TweetsAjout from "../views/TweetsAjout.vue";
import WBNiveaux from "../views/WBNiveaux.vue";
import { isLoggedIn } from '../utils/auth'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "WBDashboard",
    component: WBDashboard
  },
  {
    path: "/paris",
    name: "WBParisList",
    component: WBParisList
  },
  {
    path: "/publicites",
    name: "WBPUB",
    component: WBPUB
  },
  {
    path: "/categories",
    name: "WBCategories",
    component: WBCategories
  },
  {
    path: "/membres",
    name: "WBMembres",
    component: WBMembres
  },
  {
    path: "/notifications",
    name: "WBNotifications",
    component: WBNotifications
  },
  {
    path: "/annonces",
    name: "WBAnnonces",
    component: WBAnnonces
  },
  {
    path: "/achats",
    name: "WBAchats",
    component: WBAchats
  },
  {
    path: "/niveaux",
    name: "WBNiveaux",
    component: WBNiveaux
  },
  {
    path: "/tweets",
    name: "WBTweets",
    component: WBTweets
  },
  {
    path: "/tweets/:id",
    name: "TweetsAjout",
    component: TweetsAjout
  },
  {
    path: "/paris/ajouter",
    name: "WBParisAjout",
    component: WBParisAjout
  },
  {
    path: "/annonces/ajouter",
    name: "WBAnnoncesAjout",
    component: WBAnnoncesAjout
  },
  {
    path: "/categories/ajouter",
    name: "WBCategoriesAjout",
    component: WBCategoriesAjout
  },
  {
    path: "/niveaux/ajouter",
    name: "WBNiveauxAjout",
    component: WBNiveauxAjout
  },
  {
    path: "/paris/:id",
    name: "WBParis",
    component: WBParis
  },
  {
    path: "/draw/:id",
    name: "WBDraw",
    component: WBDraw
  },
  {
    path: "/paris/:id/modification",
    name: "WBParisModif",
    component: WBParisModif
  },
  {
    path: "/niveaux/:id/modification",
    name: "WBNiveauxModif",
    component: WBNiveauxModif
  },
  {
    path: "/categories/:id/modification",
    name: "WBCategoriesModif",
    component: WBCategoriesModif
  },
  {
    path: "/annonces/:id/modification",
    name: "WBAnnoncesModif",
    component: WBAnnoncesModif
  },
  {
    path: "/login",
    name: "WBLogin",
    component: WBLogin,
    meta: {
      allowAnonymous: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == 'login' && isLoggedIn()) {
    next({ path: '/' })
  }
  else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})
export default router;
