var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('v-row',{staticStyle:{"margin-bottom":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{class:_vm.isMobile() ? 'text-center' : 'text-left'},[_c('h2',[_vm._v("LES PARIS")])]),_c('v-col',{class:_vm.isMobile() ? 'text-center' : 'text-end'},[_c('v-btn',{staticClass:"ma-3 white--text",attrs:{"color":"primary","to":"/paris/ajouter"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" AJOUTER UN PARI ")],1)],1)],1),_c('v-alert',{attrs:{"border":"left","color":"indigo","dark":""}},[_c('h2',[_vm._v("Paris brouillons")])]),_c('v-data-table',{class:_vm.isMobile() ? '' : 'elevation-1',attrs:{"headers":_vm.isMobile() ? [] : _vm.headers,"items":_vm.parisBrouillons,"items-per-page":15,"hide-default-footer":_vm.isMobile() ? true : false,"no-data-text":"Aucun pari","no-results-text":"Aucun résultat"},on:{"click:row":_vm.showDetailDraw},scopedSlots:_vm._u([(_vm.isMobile())?{key:"item",fn:function(props){return [_c('v-card',{staticStyle:{"margin-bottom":"20px"},attrs:{"to":'draw/' + props.item.id}},[_c('v-img',{attrs:{"src":props.item.image,"width":"95vw"}}),_c('v-card-title',[_vm._v(_vm._s(props.item.libelle))]),_c('dic',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.dateFin))+" ")])],1)]}}:null,{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"max-width":"150"}})]}},{key:"item.dateFin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateFin))+" ")]}}],null,true)}),_c('v-alert',{attrs:{"border":"left","color":"indigo","dark":""}},[_c('h2',[_vm._v("Paris à valider")])]),_c('v-data-table',{class:_vm.isMobile() ? '' : 'elevation-1',attrs:{"headers":_vm.isMobile() ? [] : _vm.headers,"items":_vm.parisAValider,"items-per-page":15,"hide-default-footer":_vm.isMobile() ? true : false,"no-data-text":"Aucun pari","no-results-text":"Aucun résultat"},on:{"click:row":_vm.showDetail},scopedSlots:_vm._u([(_vm.isMobile())?{key:"item",fn:function(props){return [_c('v-card',{staticStyle:{"margin-bottom":"20px"},attrs:{"to":'paris/' + props.item.id}},[_c('v-img',{attrs:{"src":props.item.image,"width":"95vw"}}),_c('v-card-title',[_vm._v(_vm._s(props.item.libelle))]),_c('dic',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.dateFin))+" ")])],1)]}}:null,{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"max-width":"150"}})]}},{key:"item.dateFin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateFin))+" ")]}}],null,true)}),_c('v-alert',{attrs:{"border":"left","color":"indigo","dark":""}},[_c('h2',[_vm._v("Paris en cours")])]),_c('v-data-table',{class:_vm.isMobile() ? '' : 'elevation-1',attrs:{"headers":_vm.isMobile() ? [] : _vm.headers,"items":_vm.parisEnCours,"items-per-page":15,"no-data-text":"Aucun pari","no-results-text":"Aucun résultat","hide-default-footer":_vm.isMobile() ? true : false},on:{"click:row":_vm.showDetail},scopedSlots:_vm._u([(_vm.isMobile())?{key:"item",fn:function(props){return [_c('v-card',{staticStyle:{"margin-bottom":"20px"},attrs:{"to":'paris/' + props.item.id}},[_c('v-img',{attrs:{"src":props.item.image,"width":"95vw"}}),_c('v-card-title',[_vm._v(_vm._s(props.item.libelle))]),_c('dic',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.dateFin))+" ")])],1)]}}:null,{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"max-width":"150"}})]}},{key:"item.dateFin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateFin))+" ")]}}],null,true)}),_c('v-alert',{attrs:{"border":"left","color":"indigo","dark":""}},[_c('h2',[_vm._v("Paris terminés")])]),_c('v-data-table',{class:_vm.isMobile() ? '' : 'elevation-1',attrs:{"headers":_vm.isMobile() ? [] : _vm.headers,"items":_vm.parisTermines,"items-per-page":15,"hide-default-footer":_vm.isMobile() ? true : false,"no-data-text":"Aucun pari","no-results-text":"Aucun résultat"},on:{"click:row":_vm.showDetail},scopedSlots:_vm._u([(_vm.isMobile())?{key:"item",fn:function(props){return [_c('v-card',{staticStyle:{"margin-bottom":"20px"},attrs:{"to":'paris/' + props.item.id}},[_c('v-img',{attrs:{"src":props.item.image,"width":"95vw"}}),_c('v-card-title',[_vm._v(_vm._s(props.item.libelle))]),_c('dic',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.dateFin))+" ")])],1)]}}:null,{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"max-width":"150"}})]}},{key:"item.dateFin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dateFin))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }