<template>
  <v-container
      id="dashboard-view"
      fluid
      tag="section"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="text-left ml-3">ENVOYER UNE NOTIFICATION</h2>
        <form class="wb-form mt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  label="Titre de la notification en Français"
                  outlined
                  v-model="notification.headings.fr"
              ></v-text-field>
              <v-text-field
                  label="Titre de la notification en Anglais"
                  outlined
                  v-model="notification.headings.en"
              ></v-text-field>

              <v-text-field
                  label="Sous-titre de l'annonce en Français"
                  outlined
                  v-model="notification.subtitle.fr"
              ></v-text-field>

              <v-text-field
                  label="Sous-titre de l'annonce en Anglais"
                  outlined
                  v-model="notification.subtitle.en"
              ></v-text-field>

              <v-text-field
                  label="Description de l'annonce en Français"
                  outlined
                  v-model="notification.contents.fr"
              ></v-text-field>

              <v-text-field
                  label="Description de l'annonce en Anglais"
                  outlined
                  v-model="notification.contents.en"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.notification.contents.fr || !this.notification.contents.en || !this.notification.headings.fr || !this.notification.headings.en"
          >
            Envoyer la notification
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="6">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.notification.headings.fr || this.notification.contents.fr"
        >
          <v-row>
            <v-col cols="3">
              <img src="../assets/img/logo3d.png" style="width:100%;">
            </v-col>
            <v-col cols="9">
              <v-card-title style="font-weight: 800;padding-bottom:0;">
                {{ this.notification.headings.fr }}
              </v-card-title>
              <v-card-title style="padding-bottom:0;">
                {{ this.notification.subtitle.fr }}
              </v-card-title>
              <v-card-content style="text-align: left;display: block;padding:20px;">
                {{ this.notification.contents.fr }}
              </v-card-content>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      La notification a bien été envoyée.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. La notification n'a pas été envoyée.
    </v-snackbar>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBNotifications',
  data: function () {
    return {
      notification: {
        name: null,
        contents: {
          fr: null,
          en: null
        },
        subtitle: {
          fr: null,
          en: null
        },
        headings: {
          fr: 'WRESTLING BET',
          en: 'WRESTLING BET'
        }
      },
      success: false,
      error: false
    }
  },
  methods: {
  save() {
    axios({
      method: 'post',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/push',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      },
      data: this.notification
    }).then(() => {
      this.success = true,
      this.notification = {
        name: null,
        contents: {
          fr: null,
          en: null
        },
        subtitle: {
          fr: null,
          en: null
        },
        headings: {
          fr: 'WRESTLING BET',
          en: 'WRESTLING BET'
        }
      }
    }).catch(() => this.error = true)
  }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/users',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.users = response.data);
  }
};
</script>
<style lang="scss">
.cagnotte {
  font-weight: 800;
  font-size: 18px;
}
</style>
