<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="9">
        <h2 class="text-left ml-3">AJOUTER UNE CATÉGORIE</h2>
        <form class="wb-form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                  label="Libellé de la catégorie"
                  outlined
                  v-model="categorie.libelle"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                  label="Logo de la catégorie"
                  outlined
                  v-model="categorie.logo"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-switch
                  v-model="categorie.actif"
                  label="Actif"
                  color="primary"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
          <v-row align="center"
                 justify="center">
            <v-col cols="10" class="text-left">
              <h3>LISTE DES HASHTAGS</h3>
            </v-col>
            <v-col class="text-right" cols="2">
              <v-btn
                  class="mx-2"
                  fab
                  small
                  dark
                  color="indigo"
                  @click="showAjoutHashtag = true"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" >
              <v-list two-line>
                <v-list-item-group
                    class="pink--text"
                    multiple
                >

                  <template v-for="(item, index) in categorie.hashtags">
                    <v-list-item :key="item">
                      <template>
                        <v-list-item-content class="text-left">
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text>
                            <v-btn
                                class="mx-2"
                                fab
                                small
                                dark
                                color="indigo"
                                @click="removeHashtag(item)"
                            >
                              <v-icon dark>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                        v-if="index < categorie.hashtags.length - 1"
                        :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.categorie.libelle || !this.categorie.logo"
          >
            Enregistrer la catégorie
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="3">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.categorie.libelle || this.categorie.logo"
        >
          <v-img
              :src="categorie.logo"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.categorie.libelle }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      La catégorie a été ajoutée.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. La catégorie n'a pas été ajoutée.
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showAjoutHashtag"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Ajout d'un hashtag</v-toolbar>
          <v-card-text>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-text-field
                    label="Nom du participant"
                    outlined
                    v-model="hashtag"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                class="ma-3 white--text"
                @click="ajouterHashtag()"
            >Ajouter</v-btn>
            <v-btn
                text
                @click="dialog.value = false"
            >Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBAjoutCategories',
  data: function () {
    return {
      categorie: {
        libelle : null,
        logo: null,
        actif: true,
        hashtags: []
      },
      success: false,
      error: false,
      showAjoutHashtag: false,
      hashtag: null
    };
  },
  methods: {
    save() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/categories',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.categorie
      }).then(() => this.success = true).catch(() => this.error = true)
          .finally(() => this.categorie = null)
      setTimeout( () => this.$router.push({ path: '/categories'}), 1000);
    },
    ajouterHashtag() {
      this.categorie.hashtags.push(this.hashtag)
      this.showAjoutHashtag = false
      this.hashtag = null
    },
    removeHashtag(libelle) {
      this.categorie.hashtags.splice(this.categorie.hashtags.indexOf(libelle), 1);
    },
    removeByAttr (arr, attr, value){
      var i = arr.length;
      while(i--){
        if( arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 2 && arr[i][attr] === value ) ){

          arr.splice(i,1);

        }
      }
      return arr;
    }
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
