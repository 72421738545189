<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-data-table
        :headers="headers"
        :items="achats"
        :items-per-page="100"
        class="elevation-1"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | formatDate}}
      </template>
      <template v-slot:[`item.montant`]="{ item }">
        {{ item.montant }}€
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBAchats',
  data: function () {
    return {
      achats: [],
      headers: [
        { text: 'Membre', value: 'membre.username' },
        { text: 'Libellé', value: 'libelle' },
        { text: 'Montant', value: 'montant' },
        { text: 'Date', value: 'date' }
      ],
    }
  },
  methods: {
    showDetail (value) {
      this.$router.push('/paris/' + value.id)
    }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/achats',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.achats = response.data);
  }
};
</script>
