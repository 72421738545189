<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row class="my-3">
      <v-col class="text-left">
        <h2>LES TWEETS</h2>
      </v-col>
    </v-row>
    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Tweets à vérifier</h2>
    </v-alert>
    <v-data-table
        :headers="headers"
        :items="tweetsAVerifier"
        :items-per-page="15"
        class="elevation-1"
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.long`]="{ item }">
        <span style="color:red" v-if="item.text.length > 280">{{ item.text.length }}</span>
        <span style="color:green" v-else>{{ item.text.length }}</span>
      </template>
      <template v-slot:[`item.modifier`]="{ item }">

        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="primary"
            @click="showDetail(item)"
        >
          <v-icon dark>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.supprimer`]="{ item }">

        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="red"
            @click="supprimer(item)"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.valider`]="{ item }">

        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="green"
            @click="valider(item)"
        >
          <v-icon dark>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Tweets vérifiés</h2>
    </v-alert>
    <v-data-table
        :headers="headersValides"
        :items="tweetsVerifies"
        :items-per-page="15"
        class="elevation-1"
        @click:row="showDetail"
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.long`]="{ item }">
        <span style="color:red" v-if="item.text.length > 280">{{ item.text.length }}</span>
        <span style="color:green" v-else>{{ item.text.length }}</span>
      </template>
      <template v-slot:[`item.modifier`]="{ item }">

        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="primary"
            @click="showDetail(item)"
        >
          <v-icon dark>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.supprimer`]="{ item }">

        <v-btn
            class="mx-2"
            fab
            dark
            small
            color="red"
            @click="supprimer(item)"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Tweets publiés</h2>
    </v-alert>
    <v-data-table
        :headers="headersPublies"
        :items="tweetsPublies"
        :items-per-page="15"
        class="elevation-1"
        @click:row="showDetail"
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.long`]="{ item }">
        <span style="color:red" v-if="item.text.length > 280">{{ item.text.length }}</span>
        <span style="color:green" v-else>{{ item.text.length }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBTweets',
  data: function () {
    return {
      tweetsAVerifier: [],
      tweetsVerifies: [],
      tweetsPublies: [],
      headers: [
        { text: 'Image', value: 'image' },
        { text: 'Texte', value: 'text' },
        { text: 'Longueur', value: 'long' },
        { text: 'Source', value: 'source' },
        { text: 'Modifier', value: 'modifier' },
        { text: 'Valider', value: 'valider' },
        { text: 'Supprimer', value: 'supprimer' }
      ],
      headersValides: [
        { text: 'Image', value: 'image' },
        { text: 'Texte', value: 'text' },
        { text: 'Longueur', value: 'long' },
        { text: 'Source', value: 'source' },
        { text: 'Modifier', value: 'modifier' },
        { text: 'Supprimer', value: 'supprimer' }
      ],
      headersPublies: [
        { text: 'Image', value: 'image' },
        { text: 'Texte', value: 'text' },
        { text: 'Longueur', value: 'long' },
        { text: 'Source', value: 'source' }
      ],
    }
  },
  methods: {
    showDetail (value) {
      this.$router.push('/tweets/' + value.id)
    },
    supprimer (value) {
      axios({
        method: 'put',
        url: process.env.VUE_APP_API_ROOT_URL + '/tweets/' + value.id + '/supprimer',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then(() => {
        this.updateData()
      })
    },
    valider (value) {
      axios({
        method: 'put',
        url: process.env.VUE_APP_API_ROOT_URL + '/tweets/' + value.id + '/verifier',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then(() => {
        this.updateData()
      })
    },
    updateData() {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/tweets/draw',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.tweetsAVerifier = response.data);
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/tweets/publies',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.tweetsPublies = response.data);
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/tweets/verifies',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.tweetsVerifies = response.data);
    }
  },
  mounted () {
    this.updateData()
  }
};
</script>
