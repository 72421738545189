<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col class="text-left">
        <h2>LES NIVEAUX</h2>
      </v-col>
      <v-col class="text-end">
        <v-btn
            color="primary"
            class="ma-3 white--text"
            to="/niveaux/ajouter"
        >
          <v-icon
              left
              dark
          >
            mdi-plus
          </v-icon>
          AJOUTER UN NIVEAU
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="niveaux"
        :items-per-page="15"
        class="elevation-1"
        @click:row="showDetail"
    >
      <template v-slot:[`item.icon`]="{ item }">
        <img :src="item.icon" style="width:75px;">
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBNiveaux',
  data: function () {
    return {
      niveaux: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Visuel', value: 'icon' },
        { text: 'Libellé', value: 'libelle' },
        { text: 'Seuil', value: 'seuil' },
        { text: 'Plafond', value: 'plafond' },
        { text: 'Pub', value: 'pub' },
        { text: 'Jackpot', value: 'jackpot' }
      ],
    }
  },
  methods: {
    showDetail (value) {
      this.$router.push('/niveaux/' + value.id + '/modification')
    }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/niveaux',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.niveaux = response.data);
  }
};
</script>
