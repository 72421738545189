<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12" sm="9">
        <h2 class="text-left ml-3">MODIFIER UN NIVEAU</h2>
        <form class="wb-form">
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Libellé du niveau"
                  outlined
                  v-model="niveau.libelle"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Logo du niveau"
                  outlined
                  v-model="niveau.icon"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Seuil du niveau"
                  outlined
                  type="number"
                  v-model="niveau.seuil"
              ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
              <v-text-field
                  label="Plafond du niveau"
                  outlined
                  type="number"
                  v-model="niveau.plafond"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Valeur de la pub"
                  outlined
                  type="number"
                  v-model="niveau.pub"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Valeur du Jackpot"
                  outlined
                  type="number"
                  v-model="niveau.jackpot"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Couleur principale"
                  outlined
                  counter="6"
                  v-model="niveau.primaryColor"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                  label="Couleur secondaire"
                  counter="6"
                  outlined
                  v-model="niveau.secondaryColor"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
              color="primary"
              class="ma-3 white--text"
              @click="save()"
              :disabled="!this.niveau.libelle || !this.niveau.icon"
          >
            Modifier le niveau
          </v-btn>
          <v-btn
              color="error"
              class="ma-3 white--text"
              @click="showConfirmation = true"
          >
            Supprimer le niveau
          </v-btn>
        </form>
      </v-col>
      <v-col cols="12" sm="3">
        <h2>PREVIEW</h2>
        <v-card
            class="mx-auto mt-5"
            width="100%"
            v-if="this.niveau.libelle || this.niveau.icon"
        >
          <v-img
              :src="niveau.icon"
              width="100%"
          ></v-img>

          <v-card-title>
            {{ this.niveau.libelle }}
          </v-card-title>
          <v-card-content>
            <v-container>
            <v-row>
              <v-col>
                Seuil : {{ this.niveau.seuil }}
              </v-col>
              <v-col>
                Plafond : {{ this.niveau.plafond }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Pub : {{ this.niveau.pub }}
              </v-col>
              <v-col>
                Jackpot : {{ this.niveau.jackpot }}
              </v-col>
            </v-row>
            <v-row>
              <v-col :style="'color:white; background-color:#' + this.niveau.primaryColor">
                Couleur principale
              </v-col>
              <v-col :style="'color:white; background-color:#' + this.niveau.secondaryColor">
                Couleur secondaire
              </v-col>
            </v-row>
            </v-container>
          </v-card-content>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="success"
        color="success"
    >
      Le niveau a été modifié.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Le niveau n'a pas été modifié.
    </v-snackbar>
    <v-snackbar
        v-model="errorSupp"
        color="error"
    >
      Erreur. Le niveau ne peut pas être supprimé car il est lié à plusieurs membres.
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showConfirmation"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Supprimer un niveau</v-toolbar>
          <v-card-title>
            Êtes-vous sur de vouloir supprimer le niveau " {{ niveau.libelle }} " ?
          </v-card-title>
          <v-card-actions class="justify-end">
            <v-btn
                color="error"
                class="ma-3 white--text"
                @click="deleteNiveau()"
            >Supprimer</v-btn>
            <v-btn
                text
                @click="dialog.value = false"
            >Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBAjoutCategories',
  data: function () {
    return {
      showConfirmation: false,
      niveau: {
        libelle : null,
        icon: null,
        seuil: null,
        plafond: null,
        pub: null,
        jackpot: null,
        primaryColor: null,
        secondaryColor: null
      },
      success: false,
      errorSupp: false,
      error: false
    };
  },
  methods: {
    getNiveau () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/niveaux/' + this.$route.params.id,
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.niveau = response.data);
    },
    deleteNiveau () {
      axios({
        method: 'delete',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/niveaux/' + this.$route.params.id,
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then(() => {
        setTimeout( () => this.$router.push({ path: '/niveaux'}), 1000);
      }).catch(() => {
        this.errorSupp = true;
      });
    },
    save() {
      axios({
        method: 'put',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/niveaux',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        },
        data: this.niveau
      }).then(() => this.success = true).catch(() => this.error = true)
          .finally(() => this.niveau = null)
      setTimeout( () => this.$router.push({ path: '/niveaux'}), 1000);
    }
  },
  mounted () {
    this.getNiveau()
  }
};
</script>

<style scoped>
.wb-form{
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
}
</style>
