<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col class="text-left">
        <h2>LES CATÉGORIES</h2>
      </v-col>
      <v-col class="text-end">
        <v-btn
            color="primary"
            class="ma-3 white--text"
            to="/categories/ajouter"
        >
          <v-icon
              left
              dark
          >
            mdi-plus
          </v-icon>
          AJOUTER UNE CATÉGORIE
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="categories"
        :items-per-page="100"
        class="elevation-1"
        @click:row="showDetail"
    >
      <template v-slot:[`item.logo`]="{ item }">
        <img :src="item.logo" style="width:75px;">
      </template>
      <template v-slot:[`item.actif`]="{ item }">
        <i class="mdi mdi-check" style="color:green;font-size:30px" v-if="item.actif === 1"></i>
        <i class="mdi mdi-close" style="color:red;font-size:30px"  v-else></i>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBMembres',
  data: function () {
    return {
      categories: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Logo', value: 'logo' },
        { text: 'Libellé', value: 'libelle' },
        { text: 'Actif', value: 'actif' }
      ],
    }
  },
  methods: {
    showDetail (value) {
      this.$router.push('/categories/' + value.id + '/modification')
    }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/categories',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.categories = response.data);
  }
};
</script>
<style lang="scss">
</style>
