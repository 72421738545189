<template>
  <v-container
      fluid
  >
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showConfirmation"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Annuler un Pari</v-toolbar>
          <v-card-title>
            Êtes-vous sur de vouloir annuler ce pari ?
          </v-card-title>
          <v-card-actions class="justify-end">
            <v-btn
                color="error"
                class="ma-3 white--text"
                @click="annulerPari()"
            >OUI</v-btn>
            <v-btn
                text
                @click="dialog.value = false"
            >NON</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  <v-row>
    <v-col cols="12" sm="6">
      <v-card
          class="mx-auto mt-5"
          max-width="95%"
      >
        <v-img
            :src="pari.image"
            height="100%"
        ></v-img>

        <v-card-title>
          {{ this.pari.libelle }}
        </v-card-title>

        <v-card-subtitle>
          <v-list two-line>
            <v-list-item-group
                class="pink--text"
                multiple
            >

              <template v-for="(item, index) in pari.listeSuperstar">
                <v-list-item :key="item.nom">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.nom }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-chip
                            class="ma-2"
                            color="orange"
                            text-color="white"
                        >
                          <v-icon left>
                            mdi-star
                          </v-icon>
                          {{item.cote}}
                        </v-chip>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                    v-if="index < pari.listeSuperstar.length - 1"
                    :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-subtitle>
      </v-card>
      <v-btn color="primary" style="margin-top:10px;" :to="'/paris/' + this.$route.params.id + '/modification'" v-if="pari.statut && pari.statut.id === 1">
        <v-icon
            left
            dark
        >
          mdi-pencil
        </v-icon>
        MODIFIER LE PARI</v-btn>
      <v-btn color="primary"  style="margin-top:10px;margin-left:10px;" @click="showConfirmation = true" :loading="loadingAnnulation"  v-if="pari.statut && pari.statut.id === 1">
        <v-icon
            left
            dark
        >
          mdi-close-thick
        </v-icon>
        ANNULER LE PARI
      </v-btn>
    </v-col>
    <v-col cols="12" sm="6" v-if="pari.expired && pari.statut && pari.statut.id === 1">
      <v-row>
        <v-col cols="12">
          <v-card
              class="mx-auto mt-5"
          >
            <v-alert
                border="left"
                color="indigo"
                dark
            >
              <h2>QUI A REMPORTÉ LE MATCH ?</h2>
            </v-alert>
            <v-list>
              <v-list-item-group
                  v-model="selected"
                  multiple
                  active-class=""
                  v-for="(item, index) in pari.listeSuperstar"
                  v-bind:key="index"
              >
                <v-list-item :key="index" :value="item.nom">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.nom }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-row>
              <v-col style="padding-left:20px;">
                <v-switch
                    v-model="sendNotif"
                    label="Envoyer une notification"
                ></v-switch>
              </v-col>
              <v-col class="text-end">
                <v-btn
                    color="primary"
                    class="ma-3 white--text text-right"
                    @click="validerResultats()"
                    :loading="loading"
                >
                  <v-icon
                      left
                      dark
                  >
                    mdi-check-outline
                  </v-icon>
                  VALIDER LES RÉSULTATS
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
              class="mx-auto mt-5"
          >
            <v-card-title><h3>Nombre de paris</h3></v-card-title>
            <v-card-subtitle><h1 class="wb-chiffres">{{ nombreTotalParis }}</h1></v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
              class="mx-auto mt-5"
          >
            <v-card-title><h3>Nombre de parieurs</h3></v-card-title>
            <v-card-subtitle><h1 class="wb-chiffres">{{ nombreTotalParieurs }}</h1></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

    </v-col>
    <v-col v-else-if="pari.statut && pari.statut.id === 1">
      <v-row>
        <v-col cols="12">
          <v-card
              class="mx-auto mt-5"
          >
            <v-alert
                border="left"
                color="warning"
                dark
            >
              <v-row>
                <v-col cols="2">
                  <v-icon large>mdi-clock</v-icon>
                </v-col>
                <v-col cols="10">
                  <h2>CE PARI EST ENCORE EN COURS !</h2>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-col>
          <v-col cols="12" sm="6">
            <v-card
                class="mx-auto mt-5"
            >
              <v-card-title><h3>Nombre de paris</h3></v-card-title>
              <v-card-subtitle><h1 class="wb-chiffres">{{ nombreTotalParis }}</h1></v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card
                class="mx-auto mt-5"
            >
              <v-card-title><h3>Nombre de parieurs</h3></v-card-title>
              <v-card-subtitle><h1 class="wb-chiffres">{{ nombreTotalParieurs }}</h1></v-card-subtitle>
            </v-card>
          </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-row>
        <v-col cols="12">

          <v-card
              class="mx-auto mt-5"
          >
            <v-alert
                border="left"
                color="success"
                dark
            >
              <v-row>
                <v-col cols="2">
                  <v-icon large>mdi-check-outline</v-icon>
                </v-col>
                <v-col cols="10">
                  <h2>CE PARI A ÉTÉ VALIDÉ !</h2>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card
              class="mx-auto mt-5"
          >
            <v-card-title><h3>Nombre de paris</h3></v-card-title>
            <v-card-subtitle><h1 class="wb-chiffres">{{ nombreTotalParis }}</h1></v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
              class="mx-auto mt-5"
          >
            <v-card-title><h3>Nombre de parieurs</h3></v-card-title>
            <v-card-subtitle><h1 class="wb-chiffres">{{ nombreTotalParieurs }}</h1></v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-snackbar
        v-model="successAnnulation"
        color="success"
        :timeout="3000"
    >
      Pari annulé avec succès.
    </v-snackbar>
    <v-snackbar
        v-model="success"
        color="success"
        :timeout="3000"
    >
      Résultats enregistrés avec succès.
    </v-snackbar>
    <v-snackbar
        v-model="error"
        color="error"
    >
      Erreur. Une erreur est survenue.
    </v-snackbar>
  </v-row>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBParis',
  data: function () {
    return {
      pari: {},
      loading: false,
      loadingAnnulation: false,
      selected: null,
      error: false,
      success: false,
      successAnnulation: false,
      nombreTotalParis: null,
      nombreTotalParieurs: null,
      showConfirmation: false,
      sendNotif: true
    }
  },
  methods: {
    validerResultats() {
      this.loading = true;
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/' + this.$route.params.id + '/validation?sendNotif=' + this.sendNotif + '&vainqueurs=' + this.selected ,
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).catch(() => this.error = true).finally(() => {
        this.showSuccessSnackbar()
      });

    },
    annulerPari() {
      this.loading = true;
      this.showConfirmation = false;
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/' + this.$route.params.id + '/annulation',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).catch(() => this.error = true).finally(() => {
        this.showSuccessAnnulationSnackbar()
      });

    },
    showSuccessAnnulationSnackbar() {
      this.successAnnulation=true;
      this.loadingAnnulation= false;
      setTimeout(() => { this.$router.push('/paris'); }, 3000);
    },
    showSuccessSnackbar() {
      this.success=true;
      this.loading= false;
      setTimeout(() => { this.$router.push('/paris'); }, 3000);
    },
    getNombreTotalParis() {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/stats/paris/' + this.$route.params.id + '/paris',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.nombreTotalParis = response.data).catch(() => this.error = true);
    },
    getNombreTotalParieurs() {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/stats/paris/' + this.$route.params.id + '/parieurs',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.nombreTotalParieurs = response.data).catch(() => this.error = true);
  }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/' + this.$route.params.id + '/',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.pari = response.data);

    this.getNombreTotalParis()
    this.getNombreTotalParieurs()
  }
};
</script>
<style scoped>
.wb-chiffres {
  font-size: 60px;
  font-weight: 800;
  color:#4252b1;
  margin-top: 30px;
  margin-bottom:10px;
}
</style>
