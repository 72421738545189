<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
    <v-col v-for="stat in stats" :key="stat.libelle" cols="12" sm="6" style="padding:15px;">
        <v-row>
          <v-col cols="12">
            <h2 class="text-left ml-3">{{ stat.libelle }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="wb-title">
                Nouveaux membres
              </v-card-title>
              <v-card-text class="wb-chiffre">
                {{ stat.nouveauxMembres}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="wb-title">
                Nombre de paris
              </v-card-title>
              <v-card-text class="wb-chiffre">
                {{ stat.nombreParis}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="wb-title">
                Nombre de parieurs
              </v-card-title>
              <v-card-text class="wb-chiffre">
                {{ stat.nombreParieurs}}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="wb-title">
                Bonus visionnés
              </v-card-title>
              <v-card-text class="wb-chiffre">
                {{ stat.nombreBonus}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="wb-title">
                Joueurs : 15 bonus
              </v-card-title>
              <v-card-text class="wb-chiffre">
                {{ stat.nombreBonusComplet}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="wb-title">
                Achats Boutique
              </v-card-title>
              <v-card-text class="wb-chiffre">
                {{ stat.achats}}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="text-left ml-3">Graphiques</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" v-for="graph in graphiques" v-bind:key="graph">
        <Bar :chart-data="graph" :chart-options="{responsive: true}"
             :height="200" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: 'WBDashboard',
  components: { Bar },
  data: function () {
    return {
      stats: [],
      graphiques: []
    }
  },
  methods: {
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/statistiques',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.stats = response.data);

    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/graphiques',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.graphiques = response.data);
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lalezar&family=Staatliches&display=swap');
.wb-chiffre {
  color: #4252b1 !important;
  font-size: 55px !important;
  font-weight: 800 !important;
  font-family: 'Lalezar', cursive !important;
}
.wb-title {
  background-color: #4252b1 !important;
  color:white !important;
  font-family: 'Lalezar', cursive !important;
  margin-bottom: 30px;
  text-transform: uppercase;
}
</style>
