<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="100"
        class="elevation-1"
        @click:row="showDetail"
    >
      <template v-slot:[`item.premium`]="{ item }">
        <img src="../assets/img/wbgold.png" style="width:35px;" v-if="isPremium(item.premium)">
        <img src="../assets/img/logo3d.png" style="width:35px;" v-else>
      </template>
      <template v-slot:[`item.cagnotte`]="{ item }">
        <span class="cagnotte">{{ item.cagnotte }}</span> <img src="../assets/img/coinswb.png" style="width:25px;">
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBMembres',
  data: function () {
    return {
      users: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Premium', value: 'premium' },
        { text: 'Identifiant', value: 'username' },
        { text: 'Nom', value: 'nom' },
        { text: 'Prénom', value: 'prenom' },
        { text: 'Cagnotte', value: 'cagnotte' },
      ],
    }
  },
  methods: {
    showDetail (value) {
      this.$router.push('/paris/' + value.id)
    },
    isPremium (date) {
      if(date != null && new Date(date).getTime() > new Date().getTime()) {
        return true
      }
      return false
    }
  },
  mounted () {
    axios({
      method: 'get',
      url: process.env.VUE_APP_API_ROOT_URL + '/api/users',
      headers: {
        'Authorization': 'Bearer ' + getAuthToken()
      }
    }).then((response) => this.users = response.data);
  }
};
</script>
<style lang="scss">
.cagnotte {
  font-weight: 800;
  font-size: 18px;
}
</style>
