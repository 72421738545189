<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row align="center" justify="center" style="margin-bottom:10px;">
      <v-col :class="isMobile() ? 'text-center' : 'text-left'">
        <h2>LES PARIS</h2>
      </v-col>
      <v-col :class="isMobile() ? 'text-center' : 'text-end'">
        <v-btn
            color="primary"
            class="ma-3 white--text"
            to="/paris/ajouter"
        >
          <v-icon
              left
              dark
          >
            mdi-plus
          </v-icon>
          AJOUTER UN PARI
        </v-btn>
      </v-col>
    </v-row>

    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Paris brouillons</h2>
    </v-alert>
    <v-data-table
        :headers="isMobile() ? [] : headers"
        :items="parisBrouillons"
        :items-per-page="15"
        :class="isMobile() ? '' : 'elevation-1'"
        :hide-default-footer="isMobile() ? true : false"
        no-data-text="Aucun pari"
        no-results-text="Aucun résultat"
        @click:row="showDetailDraw"
    >
      <template v-if="isMobile()" v-slot:item="props" >
        <v-card :to="'draw/' + props.item.id" style="margin-bottom:20px;">
          <v-img :src="props.item.image" width="95vw" />
          <v-card-title>{{ props.item.libelle }}</v-card-title>
          <dic style="text-align: left">
            {{ props.item.dateFin | formatDate}}
          </dic>
        </v-card>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.dateFin`]="{ item }">
        {{ item.dateFin | formatDate}}
      </template>
    </v-data-table>
    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Paris à valider</h2>
    </v-alert>
    <v-data-table
        :headers="isMobile() ? [] : headers"
        :items="parisAValider"
        :items-per-page="15"
        :class="isMobile() ? '' : 'elevation-1'"
        :hide-default-footer="isMobile() ? true : false"
        no-data-text="Aucun pari"
        no-results-text="Aucun résultat"
        @click:row="showDetail"
    >
      <template v-if="isMobile()" v-slot:item="props" >
          <v-card :to="'paris/' + props.item.id" style="margin-bottom:20px;">
            <v-img :src="props.item.image" width="95vw" />
            <v-card-title>{{ props.item.libelle }}</v-card-title>
            <dic style="text-align: left">
              {{ props.item.dateFin | formatDate}}
            </dic>
          </v-card>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.dateFin`]="{ item }">
        {{ item.dateFin | formatDate}}
      </template>
    </v-data-table>

    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Paris en cours</h2>
    </v-alert>
    <v-data-table
        :headers="isMobile() ? [] : headers"
        :items="parisEnCours"
        :items-per-page="15"
        no-data-text="Aucun pari"
        no-results-text="Aucun résultat"
        :class="isMobile() ? '' : 'elevation-1'"
        :hide-default-footer="isMobile() ? true : false"
        @click:row="showDetail"
    >
      <template v-if="isMobile()" v-slot:item="props" >
        <v-card :to="'paris/' + props.item.id" style="margin-bottom:20px;">
          <v-img :src="props.item.image" width="95vw" />
          <v-card-title>{{ props.item.libelle }}</v-card-title>
          <dic style="text-align: left">
            {{ props.item.dateFin | formatDate}}
          </dic>
        </v-card>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.dateFin`]="{ item }">
        {{ item.dateFin | formatDate}}
      </template>
    </v-data-table>

    <v-alert
        border="left"
        color="indigo"
        dark
    >
      <h2>Paris terminés</h2>
    </v-alert>
    <v-data-table
        :headers="isMobile() ? [] : headers"
        :items="parisTermines"
        :items-per-page="15"
        :class="isMobile() ? '' : 'elevation-1'"
        :hide-default-footer="isMobile() ? true : false"
        no-data-text="Aucun pari"
        no-results-text="Aucun résultat"
        @click:row="showDetail"
    >
      <template v-if="isMobile()" v-slot:item="props" >
        <v-card :to="'paris/' + props.item.id" style="margin-bottom:20px;">
          <v-img :src="props.item.image" width="95vw" />
          <v-card-title>{{ props.item.libelle }}</v-card-title>
          <dic style="text-align: left">
            {{ props.item.dateFin | formatDate}}
          </dic>
        </v-card>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image" max-width="150" />
      </template>
      <template v-slot:[`item.dateFin`]="{ item }">
        {{ item.dateFin | formatDate}}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getAuthToken } from '../utils/auth'
import axios from 'axios'

export default {
  name: 'WBWelcome',
  data: function () {
    return {
      parisAValider: [],
      parisEnCours: [],
      parisTermines: [],
      parisBrouillons: [],
      headersMobile: [
        { text: 'ID', value: 'id', width: '100px' }
      ],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Visuel', value: 'image' },
        { text: 'Libellé', value: 'libelle' },
        { text: 'Catégorie', value: 'categorie.libelle' },
        { text: 'Statut', value: 'statut.libelle' },
        { text: 'Date de fin', value: 'dateFin' }
      ],
    }
  },
  methods: {
    showDetailDraw (value) {
      this.$router.push('/draw/' + value.id)
    },
    showDetail (value) {
      this.$router.push('/paris/' + value.id)
    },
    getParisAValider () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/a-valider/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.parisAValider = response.data);
    },
    getParisEnCours () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/actif/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.parisEnCours = response.data);
    },
    getParisBrouillon () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/draw',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.parisBrouillons = response.data);
    },
    getParisTermines () {
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_ROOT_URL + '/api/paris/termines/',
        headers: {
          'Authorization': 'Bearer ' + getAuthToken()
        }
      }).then((response) => this.parisTermines = response.data);
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.getParisEnCours()
    this.getParisAValider()
    this.getParisTermines()
    this.getParisBrouillon()
  }
};
</script>
